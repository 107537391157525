import React from "react"
import Layout from "../containers/Layout";
import * as css from "../sass/about.module.scss"
import Seo from "../containers/Seo";
import {graphql} from "gatsby";

const AboutPage = ({data}) => {
  return (
    <Layout>

      <section className={css.about}>
        <h1>会社概要</h1>

        <div className={css.table}>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>会社名</div>
            <div className={css.tableContent}>合同会社Noop (Noop, LLC.)</div>
          </div>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>設立日</div>
            <div className={css.tableContent}>2017年4月17日</div>
          </div>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>資本金</div>
            <div className={css.tableContent}>2,000,000円</div>
          </div>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>代表者</div>
            <div className={css.tableContent}>林 和幸 (Kazuyuki Hayashi)</div>
          </div>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>連絡先</div>
            <div className={css.tableContent}>050-3551-2956 / info@noop.co.jp</div>
          </div>
          <div className={css.tableRow}>
            <div className={css.tableTitle}>業務内容</div>
            <div className={css.tableContent}>システム開発 / サーバー構築・クラウド移行支援</div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
        author
        twitter
        siteUrl
      }
    }
  }
`

export const Head = ({data}) => (
  <Seo data={data}>
    <title id="title">会社概要 | {data.site.siteMetadata.title}</title>
  </Seo>
);
